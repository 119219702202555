.SecondQuotesSection .firstQuote {
    padding-top: 170px;
    font-size: 45px;
    transform: rotate(20deg);
}

.SecondQuotesSection .secondQuote {
    padding-top: 180px;
    font-size: 25px;
    float: left;
    transform: rotate(-20deg);
}

.SecondQuotesSection .thirdQuote {
    clear: both;
    float: right;
    padding-top: 180px;
    font-size: 18px;
    transform: rotate(15deg);
    padding-bottom: 80px;
}


@media all and (max-width: 600px) {
    .SecondQuotesSection .firstQuote {
        font-size: 35px;
    }
    .SecondQuotesSection .secondQuote {
        float: none;
    }
    
    .SecondQuotesSection .thirdQuote {
        float: none;
    }
}

@media all and (max-width: 380px) {
    .SecondQuotesSection .firstQuote {
        font-size: 30px;
    }
    .SecondQuotesSection .secondQuote {
        font-size: 22px;
    }
    
    .SecondQuotesSection .thirdQuote {
        font-size: 18px;
    }
}



