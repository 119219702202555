.Developers {
    margin-bottom: 35px;
}
  
.Developer{
    vertical-align: middle;
    padding: 5px;
    margin: 5px;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.6);
    width: "calc(49% - 20px)";
    display: inline-block;
    transition: all 0.3s;
}
.Developer .name{
    line-height: 250%;
    font-size: 1.8em;
}
.Developer .nickname{font-size: 0.3em;line-height: 150%;}
.Developer .work{
    color: rgb(210, 210, 210);
}
.Developer:hover{
    transform: scale(1.01);
}