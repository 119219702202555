.SectionContent 
{
    max-width: 960px;
    margin: auto;
    text-align: center;
}
.SectionContent.space {
    padding: 50px 30px; 
}
.SectionContent .SectionTitle {
    padding-top: 15px;
    padding-bottom: 20px;
}