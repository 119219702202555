.FirstQuotesSection .quoteContainer.firstQuoteContainer {
    font-size: 35px;
    transform: rotate(12deg);
    padding-top: 155px;
}

.FirstQuotesSection .quoteContainer.firstQuoteContainer .quoteAuthor {
    display: block;
}

.FirstQuotesSection .quoteContainer.downloadQuoteContainer {
    font-size: 25px;
    transform: rotate(-8deg);
    padding-top: 75px;
    padding-bottom: 120px;
    float: right;
    margin-right: 65px;
}


.FirstQuotesSection .quoteContainer.thirdQuoteContainer {
    font-size: 18px;
    transform: rotate(6deg);
    padding-top: 125px;
    padding-bottom: 120px;
}

.quoteContainer .quoteAuthor {
    display: inline-block;
    margin-left: 5px;
    font-size: 0.8em;
}

.clear {
    clear: both;
}


@media all and (max-width: 920px) {
    .FirstQuotesSection .quoteContainer.firstQuoteContainer {
        font-size: 30px;
    }
    .FirstQuotesSection .quoteContainer.downloadQuoteContainer {
        font-size: 21px;
    }
}

@media all and (max-width: 700px) {
    .FirstQuotesSection .quoteContainer.downloadQuoteContainer {
        float: none;
        padding-bottom: 15px;
        margin-right: 0;
    }
}


@media all and (max-width: 400px) {
    .FirstQuotesSection .quoteContainer.firstQuoteContainer {
        font-size: 25px;
    }
}