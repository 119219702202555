.imgContainer img {
    background: #fff;
    border: 3px solid white;
    border-radius: 15px;
    height: 80px;
    box-shadow: 0 0 17px 0 rgba(0,0,0,0.6),inset 0 0 1px 0 currentColor;
}
#mc_embed_signup input:focus {
    border: none;
    outline: none;
}
#mc_embed_signup input.email {
    border: none;
    padding: 5px;
    -webkit-appearance: none;
    font-family: 'FFF-Forward', Arial;
    font-size: 15px;
    display: inline-block;
    padding: 7px 13px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    min-height: 32px;
    width: 268px;
    max-width: 100%;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    box-sizing: border-box;
    line-height: 100%;
}
#mc_embed_signup .button{
    line-height: 100%;
    font-family: 'FFF-Forward', Arial;
    clear: both;
    background-color: #ed6c26;
    border: 0 none;
    border-radius: 4px;
    letter-spacing: .03em;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    height: 32px;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
    white-space: nowrap;
    transition: all 0.23s ease-in-out 0s;
    margin: 0 0 10px 0;
    width: 200px;
    max-width: 100%;
    box-sizing: content-box;
}

#mc_embed_signup {
    text-align: center;
    margin-top: 15px;
}

#mc_embed_signup .EmailLabel {
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
}
#mc_embed_signup .email {
    margin-left: 8px;
}
.GetUpdatesText {
    margin-bottom: 5px;
}