.FirstSectionContent {
    padding: 34px 25px;
    font-size: 30px;
}

.FirstSectionContent .StairJumpText, .FirstSectionContent .StairJumpDescriptionText {
    display: inline;
}
@media all and (max-width: 900px) {
    .FirstSectionContent {
        font-size: 20px;
    }
}

@media all and (max-width: 600px) {
    .FirstSectionContent .StairJumpText, .FirstSectionContent .StairJumpDescriptionText {
        display: block;
    }
    .FirstSectionContent {
        font-size: 16px;
    }
}
@media all and (max-width: 500px) {
    .FirstSectionContent {
        font-size: 12px;
    }
}