.slick-slide{outline: none; border: 0; cursor: pointer; }
.slick-current{cursor: inherit;}
.slick-slide,
.slick-slider .slick-track,
.slick-slider .slick-list
{
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);


  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;

}
.custom-slider-container {
    pointer-events: none;
    display: inline-block;
    white-space: nowrap;
}
.slider-background-img { 
    pointer-events: none;
    top: 5px;
    position: relative;
    width: 100%; 
    background-size: cover; 
    background-color: red;
    height: 56vw; 
    max-height: 420px;
    white-space: nowrap;
}

@media all and (min-width: 800px) {
    slick{white-space: nowrap;}
    .slick-slide {width: 700px;}
    .slick-slide .custom-slider-container {padding-top: 25px;}
    .slider-background-img{transition: transform 300ms ease; opacity: 0.6; }
    .slick-slide {
        position: relative; 
        z-index: -1;
    }
    .slick-slide.slick-center {
        z-index: 2;
    }
    .slick-slide.slick-center .custom-slider-container{
        position: relative; 
        z-index: 2;
    }
    .slick-slide.slick-center .custom-slider-container .slider-background-img{
        transform-origin: 50% 100%; 
        transform: scale(1.08); 
        opacity: 1; 
        position: relative; 
        z-index: 2;
    }
  }