.Header {
    width: 100%; 
    height: 90px; 
    position: fixed;
    z-index: 99;
    overflow: hidden;
}
.Header-PlacerHolder {
    height: 90px;
}
.Color-Section.full {
    min-height: calc(100vh - 90px);
}

.Header-Title {
    padding-top: 26px;
}
.Header-Downloads {
    float: right;
    margin-top: 28px;
}

.Header-Title h1 {
    font-weight: normal;
    display: inline-block; 
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 30px;
    font-size: 33px;
    position: relative;
    top: -13px;
}

.Header .SectionContent {
    text-align: left;
}


@media all and (max-width: 700px) {
    .Header-Downloads {
        float: none;
        margin-right: auto;
        margin-left: auto;
        display: block;
        text-align: center;
        position: relative;
        top: -12px;
    }
    .Header {
        height: 130px; 
    }

    .Header-PlacerHolder {
        height: 130px;
    }
    
    .Header-Title {
        padding-top: 0px;
        position: relative;
        top: -6px;
    }
    .Color-Section.full {
        min-height: calc(100vh - 130px);
    }
    
}

@media all and (max-width: 400px) {
    .Header-Title h1 {
        font-size: 25px;
        top: -9px;
    }
}

@media all and (max-width: 340px) {
    .Header-Downloads img {
        height: 30px;
    } 
    .Header-Title {
        margin-top: 11px;
    }
    .Header-Title h1 {
        font-size: 22px;
    }
}