body {
  font-family: 'FFF-Forward',Arial;
  text-shadow: 0 1px rgba(0,0,0,0.7);
}

@font-face{
  font-family:'FFF-Forward';
  src:url('fonts/hinted-FFFForward.eot');
  src:url('fonts/hinted-FFFForward.eot?#iefix') format('embedded-opentype')
  ,url('fonts/FFFForward.woff2') format('woff2')
  ,url('fonts/hinted-FFFForward.woff') format('woff')
  ,url('fonts/hinted-FFFForward.ttf') format('truetype')
  ,url('fonts/hinted-FFFForward.svg#FFFForward') format('svg');
  font-weight:normal;
  font-style:normal;
  font-display: swap;
}

b {
  font-weight: normal;
  font-size: 1.4em;
}

p {
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

a {
  color: #f0f0f0;
  text-decoration: none;
}