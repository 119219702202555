body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  margin: 0;
}

.PurpleText {
  color: #ba36c9;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: currentColor;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: currentColor;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: currentColor;
}

html, body, #root {
  height: 100%;
}